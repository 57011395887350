<template>
  <div>
    <b-row>
      <b-col md="8">
        <h2>Licenses</h2>
        <hr>
      </b-col>
      <b-col
        md="1"
        class="align-content-center pb-1"
      >
        <p>Search</p>
      </b-col>
      <b-col>
        <b-form-group>
          <div class="d-flex align-items-right">
            <b-form-input
              v-model="searchFilterTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="filterRows"
      :rtl="direction"
      :search-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span v-if="props.column.field === 'FullName'">
          <router-link
            :to="'/licenses/license-editor/' + props.row.UserAccountID"
          >{{ props.row.FullName }}</router-link>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

    </vue-good-table>
  </div>
</template>

<script>
import store from "@/store";
import APIService from "@core/utils/APIService";
import {VueGoodTable} from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import {BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";

const apiService = new APIService();


export default {
  components: {
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
  },
  data() {
    return {
      rows: [],
      filterRows: [],
      columns: [
        {
          label: "Name",
          field: "FullName",
        },
        {
          label: "User Type",
          field: "UserType",
        },
        {
          label: "Email",
          field: "EMail",
        },
      ],
      searchFilterTerm: "",
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    searchFilterTerm(val) {
      this.filterRows = this.rows.filter(i => i.FullName.toLowerCase().indexOf(val.toLowerCase()) !== -1 || i.EMail.toLowerCase().indexOf(val.toLowerCase()) !== -1)
    }
  },
  created() {
    this.loadLicenses();
  },
  methods: {
    loadLicenses() {
      apiService
          .get("licenses/userlist")
          .then(res => {
            this.filterRows = this.rows = res.data;
          })
    },
  },
}
</script>

<style scoped>

</style>